import React, { useEffect, useRef, useState } from 'react'
import axios from 'axios'
import './App.css'

// eslint-disable-next-line @typescript-eslint/no-var-requires
const elementResizeDetectorMaker = require('element-resize-detector')
const erd = elementResizeDetectorMaker()

const milestone = {
    label: 'counter',
    count: 40000
}

const ProgressBar = ({ percentage, scale, width }) => {
    return (
        <div style={{ position: 'relative' }}>
            <div
                style={{ position: 'absolute', left: `${((1895 - width) / 2) * -1}px`, width: `0` }}
            >
                <div
                    className="progress-container"
                    style={{ transform: `scale(${scale > 1 ? 1 : scale})` }}
                >
                    <div className="progress-container-left" />
                    <div className="progress-bar">
                        <Filler percentage={percentage} />
                    </div>
                    <div className="progress-container-right" />
                </div>
            </div>
        </div>
    )
}

const Filler = (props) => {
    return (
        <>
            <div className="filler" style={{ width: `${props.percentage}%` }} />
            <div className="filler-effect" />
            <div className="gloss" />
        </>
    )
}

const Label = ({ completed, total, trembling }) => {
    let label
    if (completed === total) {
        label = (
            <>
                <span className="label-completed">{total.toLocaleString()}</span>
            </>
        )
    } else if (completed === 0) {
        label = <></>
    } else {
        label = (
            <>
                {completed.toLocaleString()} / {total.toLocaleString()}
            </>
        )
    }

    return <div className={`progress-title${trembling ? ' tremble' : ''}`}>{label}</div>
}

function App() {
    const [killCount, setKillCount] = useState(0)
    const [scale, setScale] = useState(1)
    const [width, setWidth] = useState(0)
    const [trembling, setTrembling] = useState(false)
    const containerRef = useRef(null)

    useEffect(() => {
        const ctnRef = containerRef.current

        erd.listenTo(ctnRef, (element) => {
            setWidth(element.offsetWidth)
            setScale(element.offsetWidth / 1895)
        })

        return () => {
            erd.removeAllListeners(ctnRef)
        }
    }, [containerRef, killCount])

    useEffect(() => {
        let pollHandle = null
        let trembleHandle = null

        function poll() {
            axios
                .get('https://nurgle-event-api.fatsharkgames.se/getstatistic/killCount')
                .then((result) =>
                    setKillCount((previous) => {
                        const next = parseInt(result.data)

                        if (previous !== 0 && previous !== next) {
                            setTrembling(true)
                            trembleHandle = setTimeout(() => {
                                setTrembling(false)
                            }, 2000)
                        }

                        return next
                    })
                )
                .then(() => {
                    pollHandle = setTimeout(poll, Math.random() * (15000 - 4800) + 4800)
                })
        }

        poll()

        return () => {
            clearTimeout(pollHandle)
            clearTimeout(trembleHandle)
        }
    }, [setKillCount])

    return (
        <div className="App">
            <header className="header" ref={containerRef}>
                <Label completed={killCount} total={milestone.count} trembling={trembling} />
                <ProgressBar
                    percentage={(killCount / milestone.count) * 100}
                    scale={scale}
                    width={width}
                />
            </header>
        </div>
    )
}

export default App
